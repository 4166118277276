<template>
    <v-data-table
        id="dataTable"
        height="90vh"
        :headers="headers"
        :items="genbaData"
        no-data-text="データーがありません"
        :footer-props="{
            showFirstLastPage: true,
            'items-per-page-text': '表示数',
            'items-per-page-options': [20, 50, -1],
        }"
    >
        <template v-slot:item.genbaName="genba">
            {{ genbaKoushuData.genba[genba.item.genbaCode] }}
        </template>

        <template v-slot:item.day="genba">
            {{ genba.item.day }}
        </template>

        <template v-slot:item.kyuuKei="genba">
            {{ $gf.convertTime(genba.item.kyuuKei) }}
        </template>

        <template v-slot:item.idou="genba">
            {{ $gf.convertTime(genba.item.idou) }}
        </template>

        <template v-slot:item.shoteiTime="genba">
            {{ $gf.convertTime(genba.item.shotei) }}
        </template>

        <template v-slot:item.shoteiRate="genba">
            {{ $gf.convertYen(genba.item.shoteiK, true) }}
        </template>

        <template v-slot:item.zangyouTime="genba">
            {{ $gf.convertTime(genba.item.zanGyou) }}
        </template>

        <template v-slot:item.zangyouRate="genba">
            {{ $gf.convertYen(genba.item.zanGyouK, true) }}
        </template>

        <template v-slot:item.kyuushutsuTime="genba">
            {{ $gf.convertTime(genba.item.kyuuShutsu) }}
        </template>

        <template v-slot:item.kyuushutsuRate="genba">
            {{ $gf.convertYen(genba.item.kyuuShutsuK, true) }}
        </template>

        <template v-slot:item.shinyaTime="genba">
            {{ $gf.convertTime(genba.item.shinya) }}
        </template>

        <template v-slot:item.shinyaRate="genba">
            {{ $gf.convertYen(genba.item.shinyaK, true) }}
        </template>

        <template v-slot:item.grandTotalK="genba">
            {{
                $gf.convertYen(
                    genba.item.shoteiK +
                        genba.item.zanGyouK +
                        genba.item.kyuuShutsuK +
                        genba.item.shinyaK,
                    true
                )
            }}
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: ["genbaData", "genbaKoushuData"],
    data: () => ({
        headers: [
            { text: "現場コード", value: "genbaCode" },
            { text: "現場", value: "genbaName" },
            { text: "出勤日数", value: "day" },
            { text: "休憩時間", value: "kyuuKei" },
            { text: "移動時間", value: "idou" },
            { text: "所定(時間)", value: "shoteiTime" },
            { text: "所定(金額)", value: "shoteiRate" },
            { text: "残業(時間)", value: "zangyouTime" },
            { text: "残業(金額)", value: "zangyouRate" },
            { text: "休出(時間)", value: "kyuushutsuTime" },
            { text: "休出(金額)", value: "kyuushutsuRate" },
            { text: "深夜(時間)", value: "shinyaTime" },
            { text: "深夜手当", value: "shinyaRate" },
            { text: "合計", value: "grandTotalK" },
        ],
    })
};
</script>

<style>
</style>