<template>
    <v-data-table
        id="dataTable"
        height="90vh"
        v-if="genbaData"
        :headers="headers"
        :items="[genbaData]"
        no-data-text="データーがありません"
        :footer-props="{
            showFirstLastPage: true,
            'items-per-page-text': '表示数',
            'items-per-page-options': [20, 50, -1],
        }"
    >   
        <template v-slot:item="{ item }">
            <v-simple-table>
                <thead>
                    <tr>
                        <th v-for="subH in subHeaders" :key="subH.value">
                            {{subH.text}}
                        </th>
                    </tr>
                </thead>
            </v-simple-table>
            <div v-for="(user,prop) in item" :key="prop">
                <tr >
                    <td class="pa-3">
                        <v-chip color=primary class="teamName">
                            {{genbaKoushuData.genba[prop]}}
                        </v-chip>
                    </td>
                </tr>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                            <tr v-for="(u,name) in user" :key="name">
                                <td>
                                    {{name}}
                                </td>
                                <td>{{u.day}}</td>
                                <td>{{$gf.convertTime(u.kyuuKei)}}</td>
                                <td>{{$gf.convertTime(u.idou)}}</td>
                                <td>{{$gf.convertTime(u.shotei)}}</td>
                                <td>{{$gf.convertYen(u.shoteiK, true)}}</td>
                                <td>{{$gf.convertTime(u.zanGyou)}}</td>
                                <td>{{$gf.convertYen(u.zanGyouK, true)}}</td>
                                <td>{{$gf.convertTime(u.kyuuShutsu)}}</td>
                                <td>{{$gf.convertYen(u.kyuuShutsuK, true)}}</td>
                                <td>{{$gf.convertTime(u.shinya)}}</td>
                                <td>{{$gf.convertYen(u.shinyaK, true)}}</td>
                                <td>
                                    {{ 
                                        $gf.convertYen(
                                            u.shoteiK+u.zanGyouK+u.kyuuShutsuK+u.shinyaK,
                                            true
                                        ) 
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </template>
    </v-data-table>
</template>

<script>

export default {
    props:['userData', 'genbaKoushuData'],
    data:()=>({
        headers:[
            {text:"", value:"user"},
        ],
        subHeaders:[
            {text:"名前", value:"name"},
            {text:"出勤日数", value:"day"},
            {text:"休憩時間", value:"kyuuKei"},
            {text:"移動時間", value:"idou"},
            {text:"所定(時間)", value:"shoteiTime"},
            {text:"所定(金額)", value:"shoteiRate"},
            {text:"残業(時間)", value:"zangyouTime"},
            {text:"残業(金額)", value:"zangyouRate"},
            {text:"休出(時間)", value:"kyuushutsuTime"},
            {text:"休出(金額)", value:"kyuushutsuRate"},
            {text:"深夜(時間)", value:"shinyaTime"},
            {text:"深夜手当", value:"shinyaRate"},
            {text:"合計", value:"grandTotalK"},
        ],
        genbaData:null
    }),
    mounted(){
        this.genbaData = this.groupByGenba(this.userData)
    },
    methods:{
        groupByGenba(users){
            let tmpGenba = {}
            for(let i = 0 ; i<users.length ; i++){
                let user = users[i]
                if(!user.finalized) continue
                for(let genbaCode in user.finalized){
                    if(!tmpGenba[genbaCode])
                        tmpGenba[genbaCode] = {}
                    tmpGenba[genbaCode][user.userId.name] = user.finalized[genbaCode]
                }
            }
            return tmpGenba
        }
    }
};
</script>

<style>
</style>