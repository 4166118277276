<template>
    <v-data-table
        id="dataTable"
        height="90vh"
        :headers="headers"
        :items="userData"
        no-data-text="データーがありません"
        :footer-props="{
            showFirstLastPage: true,
            'items-per-page-text': '表示数',
            'items-per-page-options': [20, 50, -1],
        }"
    >
        <template v-slot:item.day="{ item }">
            {{ item.groupedRecords ? Object.keys(item.groupedRecords).length : 0}}
        </template>
        <!--template v-slot:item.roudou="{ item }">
            {{ $gf.convertTime(item.finalized.totalTime) }}
        </!--template-->
        <template v-slot:item.totalKyuKei="{ item }">
            {{ item.finalized ? $gf.convertTime(item.finalized.kyuuKei) : 0}}
        </template>
        <template v-slot:item.totalIdou="{ item }">
            {{ item.finalized ? $gf.convertTime(item.finalized.idou) : 0}}
        </template>
        <template v-slot:item.shoteiTime="{ item }">
            {{ item.finalized ? $gf.convertTime(item.finalized.shotei) : 0}}
        </template>
        <template v-slot:item.shoteiRate="{ item }">
            {{
                item.finalized ? 
                    item.finalized.shoteiK === null
                        ? "単価未設定"
                        : $gf.convertYen(item.finalized.shoteiK, true)
                : 0
            }}
        </template>
        <template v-slot:item.zangyouTime="{ item }">
            {{ item.finalized ? $gf.convertTime(item.finalized.zanGyou) : 0 }}
        </template>
        <template v-slot:item.zangyouRate="{ item }">
            {{
                item.finalized ? 
                    item.finalized.zanGyouK === null
                        ? "単価未設定"
                        : $gf.convertYen(item.finalized.zanGyouK, true)
                : 0
            }}
        </template>
        <template v-slot:item.kyuushutsuTime="{ item }">
            {{ item.finalized ? $gf.convertTime(item.finalized.kyuuShutsu) : 0 }}
        </template>
        <template v-slot:item.kyuushutsuRate="{ item }">
            {{
                item.finalized ? 
                    item.finalized.kyuuShutsuK === null
                        ? "単価未設定"
                        : $gf.convertYen(item.finalized.kyuuShutsuK, true)
                : 0
            }}
        </template>
        <template v-slot:item.shinyaTime="{ item }">
            {{ item.finalized ? $gf.convertTime(item.finalized.shinya) : 0}}
        </template>
        <template v-slot:item.shinyaRate="{ item }">
            {{
                item.finalized ? 
                    item.finalized.shinyaK === null
                        ? "単価未設定"
                        : $gf.convertYen(item.finalized.shinyaK, true)
                : 0
            }}
        </template>
        <template v-slot:item.grandTotalK="{ item }">
            {{
                item.finalized ? 
                    item.finalized.grandTotalK === null
                        ? "単価未設定"
                        : $gf.convertYen(item.finalized.grandTotalK, true)
                : 0
            }}
        </template>
    </v-data-table>
</template>

<script>
export default {
    props:['userData'],
    data:()=>({
        headers:[
            {text:"所属", value:"userId.userTeam.teamName"},
            {text:"名前", value:"userId.name"},
            {text:"出勤日数", value:"day"},
            //{text:"稼働時間", value:"roudou"},
            {text:"休憩時間", value:"totalKyuKei"},
            {text:"移動時間", value:"totalIdou"},
            {text:"所定(時間)", value:"shoteiTime"},
            {text:"所定(金額)", value:"shoteiRate"},
            {text:"残業(時間)", value:"zangyouTime"},
            {text:"残業(金額)", value:"zangyouRate"},
            {text:"休出(時間)", value:"kyuushutsuTime"},
            {text:"休出(金額)", value:"kyuushutsuRate"},
            {text:"深夜(時間)", value:"shinyaTime"},
            {text:"深夜手当", value:"shinyaRate"},
            {text:"合計", value:"grandTotalK"},
        ]
    })
};
</script>

<style>
</style>