var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"id":"dataTable","height":"90vh","headers":_vm.headers,"items":_vm.userData,"no-data-text":"データーがありません","footer-props":{
        showFirstLastPage: true,
        'items-per-page-text': '表示数',
        'items-per-page-options': [20, 50, -1],
    }},scopedSlots:_vm._u([{key:"item.day",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.groupedRecords ? Object.keys(item.groupedRecords).length : 0)+" ")]}},{key:"item.totalKyuKei",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? _vm.$gf.convertTime(item.finalized.kyuuKei) : 0)+" ")]}},{key:"item.totalIdou",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? _vm.$gf.convertTime(item.finalized.idou) : 0)+" ")]}},{key:"item.shoteiTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? _vm.$gf.convertTime(item.finalized.shotei) : 0)+" ")]}},{key:"item.shoteiRate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? item.finalized.shoteiK === null ? "単価未設定" : _vm.$gf.convertYen(item.finalized.shoteiK, true) : 0)+" ")]}},{key:"item.zangyouTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? _vm.$gf.convertTime(item.finalized.zanGyou) : 0)+" ")]}},{key:"item.zangyouRate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? item.finalized.zanGyouK === null ? "単価未設定" : _vm.$gf.convertYen(item.finalized.zanGyouK, true) : 0)+" ")]}},{key:"item.kyuushutsuTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? _vm.$gf.convertTime(item.finalized.kyuuShutsu) : 0)+" ")]}},{key:"item.kyuushutsuRate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? item.finalized.kyuuShutsuK === null ? "単価未設定" : _vm.$gf.convertYen(item.finalized.kyuuShutsuK, true) : 0)+" ")]}},{key:"item.shinyaTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? _vm.$gf.convertTime(item.finalized.shinya) : 0)+" ")]}},{key:"item.shinyaRate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? item.finalized.shinyaK === null ? "単価未設定" : _vm.$gf.convertYen(item.finalized.shinyaK, true) : 0)+" ")]}},{key:"item.grandTotalK",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.finalized ? item.finalized.grandTotalK === null ? "単価未設定" : _vm.$gf.convertYen(item.finalized.grandTotalK, true) : 0)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }