<template>
    <div>
        <div v-if="!toukeiSelection" style="display:grid; height:90vh; place-items:center;">
            <div class="d-flex justify-center align-center">
                <div class="ma-3">
                    <div class="caption font-weight-bold grey--text">
                        開始と終了時間を選んでください
                    </div>
                    <v-date-picker
                        locale="ja-JP"
                        range
                        v-model="queryDate.input"
                    ></v-date-picker>
                </div>
                <div class="ma-3">
                    <div class="text-h4 text-center">
                        {{ queryDate.from || "「開始」" }}から{{ queryDate.to || "「終了」"}}まで
                    </div>
                    <div
                        class="toukeiBtnsCon"
                        v-if="queryDate.from && queryDate.to"
                    >
                        <v-btn
                            class="headline font-weight-bold primary"
                            v-if="!toukeiSelection"
                            @click="
                                init(1);
                            "
                            >個人別</v-btn
                        >
                        <v-btn
                            class="headline font-weight-bold primary"
                            v-if="!toukeiSelection"
                            @click="
                                init(2);
                            "
                            >個人別現場別統計</v-btn
                        >
                        <v-btn
                            class=" headline font-weight-bold primary"
                            v-if="!toukeiSelection"
                            @click="
                                init(3);
                            "
                            >現場別統計</v-btn
                        >
                        <v-btn
                            class=" headline font-weight-bold primary"
                            v-if="!toukeiSelection"
                            @click="
                                init(4);
                            "
                            >現場別個人別統計</v-btn
                        >
                        <v-btn
                            class=" headline font-weight-bold primary"
                            v-if="!toukeiSelection"
                            @click="
                                init(5);
                            "
                            >出勤簿</v-btn
                        >
                        <v-btn
                            class=" headline font-weight-bold primary"
                            v-if="!toukeiSelection"
                            @click="
                                init(6);
                            "
                            >個人別有給集計</v-btn
                        >
                    </div>
                </div>
            </div>
        </div>
        <div v-if="toukeiSelection"  class="d-flex my-3">
            <div>
                <v-btn @click="reset()" color="error">戻る</v-btn>
                <span class="d-flex align-center">
                    <div class="caption">
                        {{
                            queryDate.from.split("-")[0] +
                            "年" +
                            queryDate.from.split("-")[1] +
                            "月" +
                            queryDate.from.split("-")[2] +
                            "日"
                        }}
                        から
                        {{
                            queryDate.to.split("-")[0] +
                            "年" +
                            queryDate.to.split("-")[1] +
                            "月" +
                            queryDate.to.split("-")[2] +
                            "日"
                        }}
                        までの統計データ
                    </div>
                    <div v-if="errored.value">
                        {{`${errored.value}箇所が計算できなかった`}}
                    </div>
                </span>
            </div>
            <v-spacer></v-spacer>
            <span>
                <v-btn @click="download_table_as_csv('dataTable')" color="green">
                    <v-icon>
                        mdi-file-download
                    </v-icon>
                    CSV エキスポート
                </v-btn>
            </span>
        </div>
        <!----Tables---->
        <indi-table 
            v-if="refined && toukeiSelection === 1" 
            :userData="userData"/>
        <indi-genba-table 
            v-if="refined && toukeiSelection === 2" 
            :userData="userData" 
            :genbaKoushuData="genbaKoushuData"/>
        <genba-table 
            v-if="refined && toukeiSelection === 3" 
            :genbaData="finalGenbaSummarizedData" 
            :genbaKoushuData="genbaKoushuData"/>
        <genba-indi-table 
            v-if="refined && toukeiSelection === 4" 
            :userData="userData" 
            :genbaKoushuData="genbaKoushuData"/>
        <shukinbo 
            v-if="refined && toukeiSelection === 5" 
            :queryDate="queryDate"
            :userData="userData" />
        <!----->
        <div v-if="loadingBar.show && !refined">
            <v-progress-linear
                color="blue lighten-2"
                buffer-value="0"
                stream
            ></v-progress-linear>
            <v-progress-linear
                dark
                v-model="loadingBar.value"
                color="blue darken-1"
                height="25"
            >
                <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                </template>
            </v-progress-linear>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)
dayjs.extend(isBetween);
import indiTable from '@/components/Toukei/indi-table.vue'
import indiGenbaTable from '@/components/Toukei/indi-genba-table.vue'
import genbaIndiTable from '@/components/Toukei/genba-indi-table.vue'
import genbaTable from '@/components/Toukei/genba-table.vue'
import shukinbo from '@/components/Toukei/shukinbo.vue'
export default {
    data: () => ({
        errored:{
            value : 0,
        },
        formRules: {
            queryDate: [
                (v) => !!v, //Required
                (v) => v.length <= 10,
            ],
        },
        loadingBar: {
            show: false,
            value: 0,
        },
        toukeiSelection: null, // 1 = 個人ごと, 2 = 現場ごと
        queryDate: {
            input: [],
            from: "",
            to: "",
        },
        refined:false,
        //RawDatas
        genbaKoushuData:null,
        userData: null,
        calendarInfo: null,
        settings: null,
        userRates:null,
        //Data Table
        finalGenbaSummarizedData:null
        
    }),
    components:{
        indiTable,
        indiGenbaTable,
        genbaIndiTable,
        genbaTable,
        shukinbo
    },
    watch: {
        queryDate: {
            handler() {
                if (this.queryDate.input.length === 1) {
                    this.queryDate.from = this.queryDate.input[0];
                }
                if (this.queryDate.input.length === 2) {
                    if (
                        dayjs(this.queryDate.input[0]).diff(
                            dayjs(this.queryDate.input[1])
                        ) > 0
                    ) {
                        this.queryDate.from = this.queryDate.input[1];
                        this.queryDate.to = this.queryDate.input[0];
                    } else if (
                        dayjs(this.queryDate.input[0]).diff(
                            dayjs(this.queryDate.input[1])
                        ) < 0
                    ) {
                        this.queryDate.from = this.queryDate.input[0];
                        this.queryDate.to = this.queryDate.input[1];
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        reset(){
            this.errored={
                value : 0,
            },
            this.formRules={
                queryDate: [
                    (v) => !!v, //Required
                    (v) => v.length <= 10,
                ],
            },
            this.loadingBar={
                show: false,
                value: 0,
            },
            this.toukeiSelection=null, // 1 = 個人ごと, 2 = 現場ごと
            this.queryDate={
                input: [],
                from: "",
                to: "",
            },
            this.refined=false,
            //RawDatas
            this.genbaKoushuData=null,
            this.userData=null,
            this.calendarInfo=null,
            this.settings=null,
            this.userRates=null,
            //Data Table
            this.finalGenbaSummarizedData=null
        },
        download_table_as_csv(table_id) {
            let csv = null
            let title = `${this.queryDate.from.split('-')[0]}年${this.queryDate.from.split("-")[1]}月${this.queryDate.from.split("-")[2]}日から${this.queryDate.to.split("-")[0]}年${this.queryDate.to.split("-")[1]}月${this.queryDate.to.split("-")[2]}日までの統計データ`
            let header = null
            function makeCSVfromTable(rows, name = null, teamName = null){
                let csv = [];
                let header = null
                let special = false
                if(name && teamName){
                    csv[0] = `${teamName},${name}`
                    special = true
                }
                    
                for (let i = 0; i < rows.length; i++) {
                    let row = []
                    let cols = rows[i].querySelectorAll("td, th")
                    for (let j = 0; j < cols.length; j++) {
                        let data = cols[j].innerText
                            .replace(/(\r\n|\n|\r)/gm, "")
                            .replace(/(\s\s)/gm, " ");
                        data = data.replace(/"/g, '""');
                        // Push escaped string
                        row.push('"' + data + '"');
                    }
                    if(special){
                        if(i === 0)
                            header = '"所属","名前",'+row.join(",")
                        else
                            csv.push('" "," ",'+row.join(","));
                    }else csv.push(row.join(","));
                    
                }
                if(special) return {csv, header}
                else return csv
            }
            let rows = document.getElementById(table_id).getElementsByTagName("table")[0].rows
            if(this.toukeiSelection === 2){
                let rowsInRow = []
                for(let i = 0 ; i<rows.length ; i++){
                    if(i !== 0){
                        let teamName = rows[i].getElementsByClassName("teamName")[0].innerText
                        let name = rows[i].getElementsByClassName("name")[0].innerText
                        let result = makeCSVfromTable(rows[i].getElementsByTagName("table")[0].rows, name, teamName)
                        csv = result.csv
                        header = result.header
                        rowsInRow = rowsInRow.concat(csv)
                        //let csv_string = csv.join("\n");
                    }
                }   
                csv = [header, ...rowsInRow]
            }
            else if(this.toukeiSelection === 4){
                csv = makeCSVfromTable(document.getElementById(table_id).getElementsByTagName("table")[0].getElementsByTagName("tr"))
            }else{
                csv = makeCSVfromTable(rows)
            }
            // Download it*/
            let csv_string = csv.join("\n");
            let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
            let blob = new Blob([bom, csv_string], { type: "text/csv" });
            let url = (window.URL || window.webkitURL).createObjectURL(blob);
            let filename = ""
            if(this.toukeiSelection === 1) filename = "[個人別集計]"
            if(this.toukeiSelection === 2) filename = "[個人別現場別集計]"
            if(this.toukeiSelection === 3) filename = "[現場別集計]" 
            filename += title + ".csv";
            let link = document.createElement("a");
            link.style.display = "none";
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async init(toukeiMethod) {
            //Check queryDate
            if(!this.queryDate.from && !this.queryDate.to)
                this.$store.commit('setSnack',{
                    display:true,
                    iconColor:"error",
                    icon:"mdi-alert-circle",
                    text: "開始と終了時間を選んでください"
                })
            this.toukeiSelection = toukeiMethod
            this.loadingBar.show = true;
            const response = await this.$store.dispatch("get", {
                url: `/api/getAllUserRecord?f=${this.queryDate.from}&t=${this.queryDate.to}`,
            });
            if (!response.result) return;
            //Get userData
            this.userData = response.result;
            this.loadingBar.value = 10;
            //Get calendarData
            this.calendarInfo = this.$gf.parseEventRules(
                await this.$get.getCalendarRules(),
                true
            );
            this.calendarInfo = this.$gf.filterDatesBetween(
                this.calendarInfo,
                dayjs(this.queryDate.from),
                dayjs(this.queryDate.to)
            );
            this.loadingBar.value = 12;
            //Get Settings
            this.settings = await this.$get.getSettings();
            //Turn settings into objects
            let tmpSettings = {};
            this.settings.forEach((setting) => {
                tmpSettings[setting.codeName] = setting.value;
            });
            this.settings = tmpSettings;
            this.loadingBar.value = 14;
            //Get userRates
            this.userRates = await this.$get.getUserRate(this.settings.TANKAKEISANHOUHOU)

            let remainingLoadingBarValue = 80 - this.loadingBar.value;
            let eachUserCompletionPercentage =
                remainingLoadingBarValue / this.userData.length;

            this.userData.forEach((user) => {
                /*
                    toukeiMethod：
                    [1]：個人別
                    [2]：個人別現場別
                    [3]：現場別
                    [4]：現場別個人別
                    [5]：出勤簿
                    [6]：年次有給暇管理簿
                    From here on, we will mutate each user
                    by adding desired properties

                    個人別集計の流れ:
                        データーを丸める　→　設定によりレコードを日分けでグループ化させる　→
                        所定や残業などをレコードごとに計算する　→　ユーザーごとの時間の合計を計算する　→
                        単価を計算する　→　金額を出す
                    
                */
                //=========個人別==========
                if(user.records.length > 0){
                    this.applyMarume(user);
                    this.groupRecords(user);
                    if(toukeiMethod === 1){ //個人別
                        this.calculateShotei(user);
                        this.finalizingGroupedData(user)
                        this.applyUserRate(user, this.userRates, this.settings.TANKAKEISANHOUHOU)
                        this.calculateKinkaku(user)
                    }   
                    if(toukeiMethod === 2 || toukeiMethod === 3 || toukeiMethod === 4){
                        this.calculateGenbaShotei(user);
                        this.summarizeUserGenba(user);
                        this.applyUserRate(user, this.userRates, this.settings.TANKAKEISANHOUHOU)
                        this.calculateGenbaKinkakuByUserRate(user)
                        if(toukeiMethod === 2)
                            this.convertFinalizedDataIntoArray(user)
                    }
                }
                //
                //
                //========================
                this.loadingBar.value =
                    this.loadingBar.value + eachUserCompletionPercentage > 80
                        ? 80
                        : this.loadingBar.value + eachUserCompletionPercentage;
            });
            if(toukeiMethod === 2 || toukeiMethod === 3 || toukeiMethod === 4){
                this.genbaKoushuData = await this.$get.getGenbaKoushu() 
                this.loadingBar.value = 85
                this.genbaKoushuData = this.mutateIntoMap(this.genbaKoushuData)
                this.loadingBar.value = 90
            }
            if(toukeiMethod === 3){//個人別残業別
                this.finalGenbaSummarizedData = this.groupDataByGenba(this.userData)
                this.loadingBar.value = 100
            }
            if(this.loadingBar.value < 100) this.loadingBar.value = 100
            this.refined = true
        },
        convertFinalizedDataIntoArray(user){
            let result = []
            for(let genba in user.finalized){
                let tmp = user.finalized[genba]
                tmp.genbaCode = genba
                result.push(tmp)
            }
            user.finalized = result
        },
        mutateIntoMap({genbaInfo, koushuInfo}){
            let genbaMap = {}
            genbaInfo.forEach(genba => {
                genbaMap[genba.genbaCode] = genba.genbaName
            });
            let koushuMap = {}
            koushuInfo.forEach(koushu => {
                koushuMap[koushu.koushuCode] = koushu.koushuName
            });
            return {
                genba : genbaMap,
                koushu : koushuMap
            }
        },
        groupDataByGenba(users){
            let tmpResult = {}
            users.forEach(user => {
                let final = user.finalized
                for(let genba in user.finalized){
                    if(!tmpResult[genba]){
                        tmpResult[genba] = {}
                        tmpResult[genba].idou = 0
                        tmpResult[genba].workingTime = 0
                        tmpResult[genba].kyuuKei = 0
                        tmpResult[genba].shotei = 0
                        tmpResult[genba].zanGyou = 0 //ここでは日残業と週残業が合算する
                        tmpResult[genba].shinya = 0
                        tmpResult[genba].kyuuShutsu = 0
                        tmpResult[genba].shoteiK = 0
                        tmpResult[genba].zanGyouK = 0
                        tmpResult[genba].kyuuShutsuK = 0
                        tmpResult[genba].shinyaK = 0
                        tmpResult[genba].day = 0
                    }
                    let target = tmpResult[genba]
                    target.idou += final[genba].idou
                    target.workingTime += final[genba].workingTime
                    target.kyuuKei += final[genba].kyuuKei
                    target.shotei += final[genba].shotei
                    target.zanGyou += final[genba].zanGyou
                    target.shinya += final[genba].shinya
                    target.kyuuShutsu += final[genba].kyuuShutsu
                    target.shoteiK += final[genba].shoteiK
                    target.zanGyouK += final[genba].zanGyouK
                    target.kyuuShutsuK += final[genba].kyuuShutsuK
                    target.shinyaK += final[genba].shinyaK
                    tmpResult[genba].day += final[genba].day
                }
            })
            //return tmpResult
            let result = []
            for(let genba in tmpResult){
                let tmp = tmpResult[genba]
                tmp.genbaCode = genba
                result.push(tmp)
            }
            return result
        },
        summarizeUserGenba(user){
            user.finalized = {}
            let final = user.finalized
            for(let day in user.groupedRecords){
                for(let genba in user.groupedRecords[day].genbaIdouMap){
                    if(!final[genba]){
                        final[genba] = {}
                        final[genba].workingTime = 0
                        final[genba].kyuuKei = 0
                        final[genba].idou = 0
                        final[genba].shotei = 0
                        final[genba].zanGyou = 0 //ここでは日残業と週残業が合算する
                        final[genba].shinya = 0
                        final[genba].kyuuShutsu = 0
                        final[genba].day = 0
                    }
                    let genbaData = user.groupedRecords[day].genbaIdouMap[genba]
                    final[genba].workingTime += genbaData.workingTime || 0
                    final[genba].kyuuKei += genbaData.totalKyuKei || 0
                    final[genba].idou += genbaData.totalIdou || 0
                    final[genba].shotei += genbaData.shotei || 0
                    final[genba].zanGyou += genbaData.zanGyou || 0
                    final[genba].shinya += genbaData.shinya || 0
                    final[genba].kyuuShutsu += genbaData.kyuuShutsu || 0
                    final[genba].day ++
                }
            }
            //Problem -> 週残業はどの現場に入れたらいいのか？
        },
        calculateGenbaKinkakuByUserRate(user){
            /*
                {text:"所定(金額)", value:"shoteiRate"},
                {text:"残業(金額)", value:"zangyouRate"},
                {text:"休出(金額)", value:"kyuushutsuRate"},
                {text:"深夜手当", value:"shinyaRate"},
            */
            let final = user.finalized
            let settings = this.settings
            for(let genba in final){
                final[genba].shoteiK = user.hourlyRate ? 
                Number(this.$gf.convertTime(final[genba].shotei)) * user.hourlyRate : null
                final[genba].zanGyouK = user.hourlyRate ? 
                (Number(this.$gf.convertTime(final[genba].zanGyou)) * user.hourlyRate)*(settings.ZANGYOUZOURITSU/100+1) : null
                final[genba].kyuuShutsuK = user.hourlyRate ? 
                (Number(this.$gf.convertTime(final[genba].kyuuShutsu)) * user.hourlyRate)*(settings.KYUSHUTSUZOURITSU/100+1) : null
                final[genba].shinyaK = user.hourlyRate ? 
                (Number(this.$gf.convertTime(final[genba].shinya || 0)) * user.hourlyRate)*(settings.SHINYAROUDOUZOURITSU/100) : null
            }   
        },
        finalizingGroupedData(user){
            user.finalized = {}
            let final = user.finalized
            let gRecord = user.groupedRecords
            final.totalTime = 0
            final.kyuuKei = 0
            final.idou = 0
            final.shotei = 0
            final.zanGyou = 0 //ここでは日残業と週残業が合算する
            final.shinya = 0
            final.kyuuShutsu = 0
            let weekShotei = {}
            for(let i in gRecord){
                if(!weekShotei[dayjs(i).week()]) weekShotei[dayjs(i).week()] = 0
                weekShotei[dayjs(i).week()] += gRecord[i].shotei
                final.totalTime += gRecord[i].totalTime
                final.kyuuKei += gRecord[i].totalKyuKei
                final.idou += gRecord[i].totalIdou
                final.shotei += gRecord[i].shotei
                final.zanGyou += gRecord[i].zanGyou
                final.shinya += gRecord[i].shinya
                final.kyuuShutsu += gRecord[i].kyuuShutsu
            }
            
            //calculate週残業
            const weekZanGyouCoeff = this.settings.SHUZANGYOUJIKAN * 60 * 60 * 1000;
            final.weekZanGyou = 0
            for(let i in weekShotei){
                if(weekShotei[i] - weekZanGyouCoeff > 0){
                    final.weekZanGyou += (weekShotei[i] - weekZanGyouCoeff)
                }
            }
            final.shotei -= final.weekZanGyou
            final.zanGyou += final.weekZanGyou

        },
        groupRecords(user) {
            // 1 = 前者にまとめる / 2 = 後者にまとめる
            const groupType = this.settings.SHINYAKOESHORI;
            user.groupedRecords = {};
            //Init each grouped records
            user.records.forEach((record) => {
                user.groupedRecords[
                    dayjs(record.recordStart).format("YYYY-MM-DD")
                ] = {};
                user.groupedRecords[
                    dayjs(record.recordEnd).format("YYYY-MM-DD")
                ] = {};
            });
            
            user.records.forEach((record) => {
                
                let target = null;
                if (groupType === 1) {
                    target =
                        user.groupedRecords[
                            dayjs(record.recordStart).format("YYYY-MM-DD")
                        ];
                } else if (groupType === 2) {
                    target =
                        user.groupedRecords[
                            dayjs(record.recordEnd).format("YYYY-MM-DD")
                        ];
                }
                //Recalculate totalTime
                target.start = target.start ? target.start : record.recordStart 
                target.end = record.recordEnd
                target.totalTime = target.totalTime
                    ? (target.totalTime += record.totalTime)
                    : record.totalTime;
                //Copy flags
                target.flags = record.flags;
                //Calcualte Shinya
                target.shinya = target.shinya ? target.shinya : 0
                let shinyaResult = this.calculateShinya(record)
                if(shinyaResult) target.shinya += shinyaResult
                
                function initGenbaMap(target){
                    if(Object.keys(target).length > 0) return target
                    target.from = null
                    target.workingTime = 0
                    target.shinya = 0
                    target.totalKyuKei = 0
                    target.totalIdou = 0
                    return target
                }
                target.genbaIdouMap = {}
                if(!target.genbaIdouMap.hasOwnProperty(record.recordGenbaStart)) target.genbaIdouMap[record.recordGenbaStart] = {}
                let targetGenba = target.genbaIdouMap[record.recordGenbaStart]
                if(this.toukeiSelection === 2 || this.toukeiSelection === 3){ //If not, then initiate value
                    targetGenba = initGenbaMap(targetGenba)
                } 
                
                //group subRecords
                target.totalKyuKei = 0 
                target.totalIdou = 0

                let hasIdou = false
                let globalStart = record.recordStart
                let globalEnd = null
                let shinyaCalculateStart = record.recordStart
                let shinyaCalculateEnd = null
                let subTarget = null
                let tmpSubRecord = null
                record.subRecords.forEach((subRecord) => {
                    let targetS = subRecord.subRecordStartGenba ? subRecord.subRecordStartGenba : record.recordGenbaStart 
                    let targetE = subRecord.subRecordEndGenba ? subRecord.subRecordEndGenba : record.recordGenbaEnd 
                    if(!target.genbaIdouMap.hasOwnProperty(targetS)) 
                        target.genbaIdouMap[targetS] = {}
                    if(!target.genbaIdouMap.hasOwnProperty(targetE)) 
                        target.genbaIdouMap[targetE] = {}
                    if (
                        this.$gf.convertLabel(subRecord.subRecordType) ===
                        "休憩"
                    ){
                        let kyukeiTarget = initGenbaMap(target.genbaIdouMap[targetS])
                        kyukeiTarget.totalKyuKei += subRecord.subTotalTime
                        target.totalKyuKei = target.totalKyuKei
                            ? (target.totalKyuKei += subRecord.subTotalTime)
                            : subRecord.subTotalTime;
                    }
                    else if (
                        this.$gf.convertLabel(subRecord.subRecordType) ===
                        "移動"
                    ){
                        if(this.toukeiSelection === 2 || this.toukeiSelection === 3){ //現場別
                            hasIdou = true
                  
                            globalEnd = subRecord.subRecordStart
                            let calculateIdouTarget = initGenbaMap(target.genbaIdouMap[targetS])
                            calculateIdouTarget.workingTime += dayjs(globalEnd).diff(dayjs(globalStart))
                            globalStart = subRecord.subRecordEnd
                            tmpSubRecord = subRecord
                            
                            if(this.settings.IDOUJIKANKASAN === 1){   
                                subTarget = initGenbaMap(target.genbaIdouMap[targetS])
                                subTarget.totalIdou += subRecord.subTotalTime
                                shinyaCalculateEnd = subRecord.subRecordEnd
                                subTarget.from = shinyaCalculateStart
                                subTarget.to = shinyaCalculateEnd
                                calculateIdouTarget.shinya += this.calculateShinya(null, true, shinyaCalculateStart, shinyaCalculateEnd)
                                shinyaCalculateStart = subRecord.subRecordEnd
                            }
                            else if(this.settings.IDOUJIKANKASAN === 2){
                                subTarget = initGenbaMap(target.genbaIdouMap[targetE])
                                subTarget.totalIdou += subRecord.subTotalTime
                                shinyaCalculateEnd = subRecord.subRecordStart
                                calculateIdouTarget.from = calculateIdouTarget.from ? calculateIdouTarget.from : shinyaCalculateStart
                                calculateIdouTarget.to = shinyaCalculateEnd
                                calculateIdouTarget.shinya += this.calculateShinya(null, true, shinyaCalculateStart, shinyaCalculateEnd)
                                shinyaCalculateStart = subRecord.subRecordStart
                            }
                        }
                        target.totalIdou = target.totalIdou
                            ? (target.totalIdou += subRecord.subTotalTime)
                            : subRecord.subTotalTime;
                    }  
                });

                if(!hasIdou){
                    targetGenba.workingTime += target.totalTime
                    targetGenba.shinya += target.shinya
                    targetGenba.from = target.start
                    targetGenba.to = record.recordEnd
                }else{
                    subTarget = initGenbaMap(target.genbaIdouMap[tmpSubRecord.subRecordEndGenba], tmpSubRecord.subRecordStart)
                    subTarget.workingTime += dayjs(record.recordEnd).diff(dayjs(globalStart))
                    subTarget.from = shinyaCalculateStart
                    subTarget.to = record.recordEnd
                    subTarget.shinya += this.calculateShinya(null, true, shinyaCalculateStart, record.recordEnd)
                }
                
            });
        },
        applyMarume(user) {
            user.records.forEach((record) => {
                record.recordStart = this.roundTo(
                    record.recordStart,
                    this.settings.MARUMESHUKKIN * 60000
                );
                record.recordEnd = this.roundTo(
                    record.recordEnd,
                    this.settings.MARUMETAIKKIN * 60000
                );
                record.totalTime = dayjs(record.recordEnd).diff(
                    dayjs(record.recordStart)
                );
                if (record.subRecords.length > 0)
                    record.subRecords.forEach((subRecord) => {
                        if (
                            this.$gf.convertLabel(subRecord.subRecordType) ===
                            "休憩"
                        ) {
                            subRecord.subRecordStart = this.roundTo(
                                subRecord.subRecordStart,
                                this.settings.MARUMEKYUKEISTART * 60000
                            );
                            subRecord.subRecordEnd = this.roundTo(
                                subRecord.subRecordEnd,
                                this.settings.MARUMEKYUKEIEND * 60000
                            );
                        } else if (
                            this.$gf.convertLabel(subRecord.subRecordType) ===
                            "移動"
                        ) {
                            subRecord.subRecordStart = this.roundTo(
                                subRecord.subRecordStart,
                                this.settings.MARUMEIDOUSTART * 60000
                            );
                            subRecord.subRecordEnd = this.roundTo(
                                subRecord.subRecordEnd,
                                this.settings.MARUMEIDOUEND * 60000
                            );
                        }
                        subRecord.subTotalTime = dayjs(
                            subRecord.subRecordEnd
                        ).diff(dayjs(subRecord.subRecordStart));
                    });
            });
        },
        /*
            Return : Rounded Date time
            Params : 
                date<String|required> : date to round <YYYY-MM-DD HH:mm:ss>
                coeff<milisecond|required> : 丸め値　例:<５分>=5000
        */
        roundTo(date, coeff) {
            date = new Date(date); //or use any other date
            return dayjs(Math.round(date.getTime() / coeff) * coeff).format(
                "YYYY-MM-DD HH:mm:ss"
            );
        },
        calculateGenbaShotei(user){
            const zanGyouCoeff = this.settings.HIZANGYOUJIKAN * 60 * 60 * 1000;
            for(let day in user.groupedRecords){
                let groupedRecord = user.groupedRecords[day]
                let zanGyouAccumulator = 0
                if(Object.keys(groupedRecord).length === 0){
                    delete user.groupedRecords[day]
                    continue
                }
                for(let genba in groupedRecord.genbaIdouMap){
                    if(genba){
                        let targetGenba = groupedRecord.genbaIdouMap[genba]
                        let cleanTotal = targetGenba.workingTime+targetGenba.totalIdou - targetGenba.totalKyuKei
                        //残業計算
                        targetGenba.zanGyou = 0
                        zanGyouAccumulator +=  cleanTotal
                        if(zanGyouAccumulator > zanGyouCoeff){
                            targetGenba.zanGyou = zanGyouAccumulator-zanGyouCoeff
                            zanGyouAccumulator = zanGyouAccumulator-zanGyouCoeff
                        }                            
                        //休日出勤・所定計算
                        targetGenba.kyuuShutsu = 0
                        if(groupedRecord.flags && groupedRecord.flags.kyuuShutsu) targetGenba.kyuuShutsu = cleanTotal - targetGenba.zanGyou
                        else targetGenba.shotei = cleanTotal - targetGenba.zanGyou
                    }
                }
            }
        },
        calculateShotei(user) {
            //所定 = 稼働時間 - 休憩 - 残業
            let records = user.groupedRecords;
            const zanGyouCoeff = this.settings.HIZANGYOUJIKAN * 60 * 60 * 1000;
            for (let day in records) {
                if(Object.keys(records[day]).length === 0){
                    delete records[day]
                    continue
                } 
                let cleanTotal =
                    records[day].totalTime -
                    (records[day].totalKyuKei ? records[day].totalKyuKei : 0);
                records[day].zanGyou =
                    cleanTotal > zanGyouCoeff ? cleanTotal - zanGyouCoeff : 0;
                records[day].kyuuShutsu = 0
                records[day].shotei = 0
                if(records[day].flags && records[day].flags.kyuuShutsu) records[day].kyuuShutsu = cleanTotal - records[day].zanGyou;
                else records[day].shotei = cleanTotal - records[day].zanGyou;

            }
        },
        calculateShinya(record, fixedMode = false, start, end) {
            /*
                開始時間 = ∂
                終了時間 = ß
                深夜開始 = a
                深夜終了 = b
            */
            let fixedStart = fixedMode ? start :record.recordStart
            let fixedtEnd = fixedMode ? end : record.recordEnd
            let shinyaStart = dayjs(
                `${fixedStart.split(" ")[0]} ${
                    this.settings.SHINYAROUDOUTIME.start
                }`
            );
            let shinyaEnd = dayjs(
                `${dayjs(fixedStart.split(" ")[0])
                    .add(1, "d")
                    .format("YYYY-MM-DD")} ${
                    this.settings.SHINYAROUDOUTIME.end
                }`
            );
            let rStart = dayjs(fixedStart)
            let rEnd  = dayjs(fixedtEnd)
            let targetStart = rStart.isBetween(
                shinyaStart,
                shinyaEnd,
                null,
                "[]"
            );
            let targetEnd = rEnd.isBetween(
                shinyaStart,
                shinyaEnd,
                null,
                "[]"
            );

            if (targetStart && targetEnd) { // a > ∂ && b > ß
                return rEnd.diff(rStart)
            } else if (targetStart && !targetEnd) { // a > ∂ < b && ß > b > a
                return shinyaEnd.diff(rStart)
            } else if (!targetStart && targetEnd) { // a > ß < b && ∂ < a < b
                return rEnd.diff(shinyaStart)
            } else if ( // a > ∂ && b < ß
                shinyaStart.isBetween(
                    rStart,
                    rEnd
                ) &&
                shinyaEnd.isBetween(
                    rStart,
                    rEnd
                )
            ) {
                return shinyaEnd.diff(shinyaStart)
            }

        },
        applyUserRate(user, rates, rateMethod){
            user.hourlyRate = null
            function calculateHourlyRate(rate, rateType ,workedTimePerDay, workedDay){
                if(rateType === 1){//時給
                    return rate
                }
                if(rateType === 2){
                    return rate/workedTimePerDay
                } 
                if(rateType === 3){
                    //月べつ勤務日数を計算する
                    let hourlyRate = 0
                    let totalWorkedDays = workedDay
                    let totalWorkedHours = totalWorkedDays * workedTimePerDay
                    let d2MRateOffSet = totalWorkedHours/160 > 0 && totalWorkedHours/160 < 1 ? 1 : (totalWorkedHours/160).toFixed(0)
                    hourlyRate = totalWorkedDays ? (rate * d2MRateOffSet)/(workedTimePerDay * totalWorkedDays) : 0
                    return hourlyRate
                }
            }
            for(let i = 0 ; i<rates.length ; i++){
                if(rateMethod === 1){
                    if(user.userId._id === rates[i]._id){
                        if(rates[i].userRate){
                            user.hourlyRate = calculateHourlyRate(
                                rates[i].userRate.rateValue,
                                rates[i].userRate.rateType, 
                                this.settings.HIZANGYOUJIKAN,
                                Object.keys(user.groupedRecords).length
                            )
                        }
                    }
                        
                }
                else if(rateMethod === 2){
                    if(rates[i].userList.find(uL => uL._id === user.userId._id)){
                        user.hourlyRate = calculateHourlyRate(
                            rates[i].rateValue,
                            rates[i].rateType, 
                            this.settings.HIZANGYOUJIKAN,
                            Object.keys(user.groupedRecords).length
                        )
                    }
                }
            }
        },
        calculateKinkaku(user){
            let settings = this.settings
            user.finalized.shoteiK = user.hourlyRate ? 
            Number(this.$gf.convertTime(user.finalized.shotei)) * user.hourlyRate : null
            user.finalized.zanGyouK = user.hourlyRate ?
            (Number(this.$gf.convertTime(user.finalized.zanGyou)) * user.hourlyRate)*(settings.ZANGYOUZOURITSU/100+1) : null
            user.finalized.kyuuShutsuK = user.hourlyRate ?
            (Number(this.$gf.convertTime(user.finalized.kyuuShutsu)) * user.hourlyRate)*(settings.KYUSHUTSUZOURITSU/100+1) : null
            user.finalized.shinyaK = user.hourlyRate ?
            (Number(this.$gf.convertTime(user.finalized.shinya)) * user.hourlyRate)*(settings.SHINYAROUDOUZOURITSU/100) : null
            user.finalized.grandTotalK = 
            Number(user.finalized.shoteiK) + 
            Number(user.finalized.zanGyouK) + 
            Number(user.finalized.kyuuShutsuK) +
            Number(user.finalized.shinyaK)
        }
    },
};
</script>

<style scoped>
.toukeiBtnsCon{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:1em;
}
.toukeiBtnsCon *{
    width:100%;
}
</style>