<template>
    <v-data-table
        id="dataTable"
        v-if="monthsInfo"
        height="90vh"
        :headers="headers"
        :items="userData"
        class="pr-10"
        style="overflow:scroll"
        no-data-text="データーがありません"
        dense
        :footer-props="{
            showFirstLastPage: true,
            'items-per-page-text': '表示数',
            'items-per-page-options': [20, 50, -1],
        }"
    >
        <template v-slot:item="{ item }">
            <tr>
                <td colspan="32" style="text-align:center">{{item.userId.name}}</td>
            </tr>
            <template v-if="item.groupedRecords">
                <template v-for="(month,prop) in monthsInfo">
                        <tr :key="item.userId._id+month">
                            <td rowspan="5">{{prop}}月</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td v-for="days in month" :key="days">
                                {{days.split("-")[2]}}
                            </td>
                        </tr>
                        <tr>
                            <td>勤務</td>
                            <td v-for="days in month" :key="days">
                                <span v-if="item.groupedRecords[days]">{{checkFlag(item.groupedRecords[days].flags) || "勤"}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>出勤</td>
                            <td v-for="days in month" :key="days">
                                <span v-if="item.groupedRecords[days]">{{customConvert(item.groupedRecords[days].start)}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>退勤</td>
                            <td v-for="days in month" :key="days">
                                <span v-if="item.groupedRecords[days]">{{customConvert(item.groupedRecords[days].end)}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>合</td>
                            <td colspan="32">{{calculateMonthFlags(item.groupedRecords, (new Date(month[0])).getMonth() )}}</td>
                        </tr>
                </template>
            </template>
            <tr v-else>
                <td colspan="32" style="text-align:center">データーなし</td>
            </tr>
        </template> 
        
    </v-data-table>
</template>

<script>
import dayjs from 'dayjs';
export default {
    props:['userData','queryDate'],
    data:()=>({
        headers:[],
        monthsInfo:0,
        dateList:null,
    }),
    mounted(){
        this.monthsInfo = this.getDaysBetweenDatesAndGroup(dayjs(this.queryDate.from), dayjs(this.queryDate.to))
    },
    methods:{
        calculateMonthFlags(data, month){
            let monthFlagInfo = {
                daysWorked:0
            }
            for(let i in data){
                if(dayjs(i).month() != month) continue
                let tmp = this.checkFlag(data[i].flags)
                if(tmp){
                    if(!monthFlagInfo[tmp] && monthFlagInfo[tmp]!= 0)
                        monthFlagInfo[tmp] = 1
                    else
                        monthFlagInfo[tmp] ++
                }else monthFlagInfo.daysWorked ++
            }
            return `
                出勤日数:${monthFlagInfo.daysWorked}    
                休出日数:${monthFlagInfo.休出 || 0}    
                有休日数:${monthFlagInfo.有休 || 0}    
                欠勤日数:${monthFlagInfo.欠勤 || 0}       
                公休日数:${monthFlagInfo.公休 || 0}    
                代休日数:${monthFlagInfo.代休 || 0}    
                振替日数:${monthFlagInfo.振替 || 0}
                `
        },
        checkFlag(data){
            let flag = {
                kyuuShutsu: "休出",
                yuuKyuu: "有休",
                kekKin: "欠勤",
                kouKyuu: "公休",
                daiKyuu: "代休",
                furiKae: "振替",
            }
            for(let i in data){
                if(data[i]){
                    return flag[i]
                }
            }
            return false
        },
        customConvert(date){
            return dayjs(date).format("HH:mm")
        },
        getMonthInfo(dateList){
            let uniqueMonth = new Set()
            dateList.forEach(date => {
                uniqueMonth.add(date.split("-")[1])
            });
            return Array.from(uniqueMonth)
        },
        getDaysBetweenDatesAndGroup(startDate, endDate){
            let now = startDate.clone(), dates = {};
            let currentMonth = startDate.get('month')+1
            dates[currentMonth] = []
            while(now.isSameOrBefore(endDate)){
                if(now.get('month')+1 !== currentMonth){
                    dates[now.get('month')+1] = []
                    currentMonth = now.get('month')+1
                }
                dates[currentMonth].push(now.format('YYYY-MM-DD'));
                now = now.add(1, 'd');
            }
            
            return dates;
        }
    }
};
</script>
